.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}

.shadow-1 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.o-auto {
  overflow: auto;
}

.c-pointer {
  cursor: pointer;
}
