.br-1 {
  border-radius: 8px;
}

.bb-1 {
  border-bottom: 1px solid;
}

.bcolor-grey {
  border-color: $colorGrey;
}
