.pa-1 {
  padding: 8px;
}

.pb-1 {
  padding-bottom: 8px;
}

.pr-1 {
  padding-right: 8px;
}

.pr-2 {
  padding-right: 16px;
}

.pl-1 {
  padding-left: 8px;
}

.pa-2 {
  padding: 16px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pv-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ph-1 {
  padding-left: 8px;
  padding-right: 8px;
}

.ma-1 {
  margin: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}
