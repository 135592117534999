.dn {
  display: none;
}

.db {
  display: block;
}

.df {
  display: flex;
}

@media #{$breakpoint-md} {
  .dn-md {
    display: none;
  }

  .df-md {
    display: flex;
  }
}
